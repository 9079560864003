function UploadFiles({ filesList }) {
  const dataValue = (param) => {
    let fileName = param.split("\\").pop().split(".");
    return fileName;
  };
  return (
    <>
      <ul className="fileListWrap">
        {filesList.map((data, index) => (
          <li className={dataValue(data)[1]} key={index}>
            {dataValue(data)[0]}
          </li>
        ))}
      </ul>
    </>
  );
}

export default UploadFiles;
