import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";

const UploadOmniXls = ({
  handleFileChange,
  fileUploadStatus,
  setFileTitle,
  pageTitle,
  firstTabValue,
  isFileAvailable,
  counter,
  setInputTargetFiles,
  isTargetAvilable,
  fileName,
}) => {
  const [error, setError] = useState("");
  const [msg, setMsg] = useState(null);
  const [displayMsg, setDisplayMsg] = useState(false);

  const handleFileUpload = (e) => {
    if (!e.target.files.length) {
      setError("No file selected.");
      fileUploadStatus(false);
      return;
    }

    const file = e.target.files[0];

    if (!file.name.endsWith(".xls") && !file.name.endsWith(".xlsx")) {
      setError("Please upload a valid Excel file.");
      return;
    }

    // Change the file name here
    const newFileName = `${fileName}${file.name.endsWith(".xlsx") ? ".xlsx" : ".xls"}`;
    const renamedFile = new File([file], newFileName, { type: file.type });

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      if (firstSheetName === firstTabValue) {
        setError(""); // Clear any previous error
        setMsg(newFileName + " File uploaded");
        setDisplayMsg(true);
        fileUploadStatus(true);
        if (isFileAvailable[counter] !== undefined) {
          isFileAvailable[counter] = newFileName;
          isTargetAvilable[counter] = renamedFile;
        } else {
          handleFileChange(newFileName);
          setInputTargetFiles(renamedFile);
        }
      } else {
        setDisplayMsg(true);
        setError(`"The first tab is not named '${firstTabValue}'."`);
      }
    };

    reader.readAsArrayBuffer(renamedFile);
  };

  useEffect(() => {
    setFileTitle(pageTitle);
    fileUploadStatus(false);
    if (isFileAvailable[counter] !== undefined) {
      setMsg(isFileAvailable[counter].split("\\").pop() + " file uploaded");
      setDisplayMsg(true);
      fileUploadStatus(true);
    }
  }, [msg, isFileAvailable, counter, setFileTitle, pageTitle, fileUploadStatus]);
  return (
    <>
      <div className="fileLabel">
        <div className="fileLabel">
          <input
            type="file"
            id="uploadOmniXls"
            accept=".xls,.xlsx"
            className="uploadInput"
            onChange={handleFileUpload}
          />
          <label htmlFor="uploadOmniXls" className="icon xls-icon"></label>
          {displayMsg && (
            <p className={`statusMsg ${error ? "errorMsg" : "succesMsg"}`}>{`${
              error ? error : msg
            }`}</p>
          )}
        </div>
      </div>
    </>
  );
};

export default UploadOmniXls;
