import tmaLogo from '../images/tma.svg'
function Header() {
    return (
        <header>
            <img src={tmaLogo} alt="TMA" />
        </header>
    )
}

export default Header
